export const state = () => ({
  loaded: false
})

export const getters = {
  isAuthenticated(state) {
    return state.auth.loggedIn
  },

  loggedInUser(state) {
    return state.auth.user
  }
}

export const mutations = {
  setLoaded(state, loaded) {
    state.loaded = loaded
  }
}

export const actions = {
  async nuxtClientInit({ commit, dispatch }, context) {
    if (!context?.$auth?.loggedIn) {
      commit('setLoaded', true)
      return
    }

    try {
      await dispatch('functions/nuxtClientInit', context)
      await dispatch('workTypes/nuxtClientInit', context)
      await dispatch('skills/nuxtClientInit', context)
      await dispatch('contract/nuxtClientInit', context)

      if (context?.$auth?.$state?.user?.role?.type === 'authenticated') {
        await dispatch('hireCompany/nuxtClientInit', context)
        await dispatch('onboarding/loadOnboardingState', context)
      }

      if (
        context?.$auth?.$state?.user?.role?.type === 'admin' ||
        context?.$auth?.$state?.user?.role?.type === 'authenticated'
      ) {
        await dispatch('clients/nuxtClientInit', context)
      }

      if (
        context?.$auth?.$state?.user?.role?.type === 'admin' ||
        context?.$auth?.$state?.user?.role?.type === 'client'
      ) {
        await dispatch('freelancer/loadFreelancers', context)
      }

      if (
        context?.$auth?.$state?.user?.client ||
        context?.$auth?.$state?.user?.freelancer
      ) {
        await dispatch('documents/nuxtClientInit', context)
      }

      if (context?.$auth?.$state?.user?.status !== 'active') {
        commit('setLoaded', true)
        return
      }

      if (
        context?.$auth?.$state?.user?.client ||
        context?.$auth?.$state?.user?.freelancer
      ) {
        await dispatch('documents/nuxtClientInit', context)
      }

      if (
        context?.$auth?.$state?.user?.role &&
        context?.$auth?.$state?.user?.role.name !== 'Client'
      ) {
        if (
          context?.$auth?.$state?.user?.client ||
          context?.$auth?.$state?.user?.freelancer
        ) {
          await dispatch('shiftRequest/loadShifts', context)
        }
      } else if (context?.$auth?.$state?.user?.role?.name === 'Client') {
        await dispatch('shiftRequest/loadClientShifts', context)
        await dispatch('favoriteFreelancers/nuxtClientInit', context)
      }
    } catch (e) {
      this.$sentry.captureException(e)
    } finally {
      await commit('setLoaded', true)
    }
  },
  resetStore({ commit }) {
    commit('clients/reset')
    commit('contract/reset')
    commit('documents/reset')
    commit('freelancer/reset')
    commit('functions/reset')
    commit('hireCompany/reset')
    commit('invoices/reset')
    commit('onboarding/reset')
    commit('shiftRequest/reset')
    commit('shifts/reset')
    commit('users/reset')
    commit('favoriteFreelancers/reset')
  }
}
