import * as qs from 'qs'

import { ApiConfig } from '../config.js'

export default {
  async fetchAll($this: any) {
    const query = qs.stringify(
      {
        filters: {
          role: {
            type: 'authenticated'
          }
        },
        populate: {
          documents: true,
          avatar: true,
          freelancer: {
            populate: {
              hire_company: true,
              function: {
                populate: {
                  function: true
                }
              },
              vat: true,
              freelancer_datastore: true
            }
          },
          user_perference: true
        }
      },
      {
        encodeValuesOnly: true
      }
    )
    const token = $this.$auth.strategy.token

    return await $this.$axios.get(`${ApiConfig.USERS.GET_ALL}?${query}`, {
      headers: {
        Authorization: token
      }
    })
  },

  async updateRegistration($this: any, data: any) {
    const token = $this.$auth.strategy.token

    return await $this.$axios.put(ApiConfig.USER.UPDATE_REGISTRATION, data, {
      headers: {
        Authorization: token
      }
    })
  },

  async updateUser($this: any, data: any) {
    const token = $this.$auth.strategy.token

    return await $this.$axios.put(ApiConfig.USER.UPDATE, data, {
      headers: {
        Authorization: token
      }
    })
  }
}
