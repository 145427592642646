const middleware = {}

middleware['auth-user'] = require('../middleware/auth-user.js')
middleware['auth-user'] = middleware['auth-user'].default || middleware['auth-user']

middleware['client-onboarded'] = require('../middleware/client-onboarded.js')
middleware['client-onboarded'] = middleware['client-onboarded'].default || middleware['client-onboarded']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['is-client-user'] = require('../middleware/is-client-user.js')
middleware['is-client-user'] = middleware['is-client-user'].default || middleware['is-client-user']

middleware['is-pending'] = require('../middleware/is-pending.js')
middleware['is-pending'] = middleware['is-pending'].default || middleware['is-pending']

middleware['isAdmin'] = require('../middleware/isAdmin.js')
middleware['isAdmin'] = middleware['isAdmin'].default || middleware['isAdmin']

middleware['not-active-freelancer'] = require('../middleware/not-active-freelancer.js')
middleware['not-active-freelancer'] = middleware['not-active-freelancer'].default || middleware['not-active-freelancer']

middleware['not-active'] = require('../middleware/not-active.js')
middleware['not-active'] = middleware['not-active'].default || middleware['not-active']

middleware['onboarded'] = require('../middleware/onboarded.js')
middleware['onboarded'] = middleware['onboarded'].default || middleware['onboarded']

middleware['preboarded'] = require('../middleware/preboarded.js')
middleware['preboarded'] = middleware['preboarded'].default || middleware['preboarded']

export default middleware
