import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _06b647af = () => interopDefault(import('../pages/client-area/index.vue' /* webpackChunkName: "pages/client-area/index" */))
const _4416c318 = () => interopDefault(import('../pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _316413ef = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _85e42d3c = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _585fa470 = () => interopDefault(import('../pages/freelancers/index.vue' /* webpackChunkName: "pages/freelancers/index" */))
const _7271b08a = () => interopDefault(import('../pages/gocredible.vue' /* webpackChunkName: "pages/gocredible" */))
const _09e891f4 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4c52c664 = () => interopDefault(import('../pages/onboarding.vue' /* webpackChunkName: "pages/onboarding" */))
const _291aad5c = () => interopDefault(import('../pages/pre-boarding/index.vue' /* webpackChunkName: "pages/pre-boarding/index" */))
const _6226e406 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _3698bc16 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _bfb408e8 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _9dc4e40e = () => interopDefault(import('../pages/client-area/approval/index.vue' /* webpackChunkName: "pages/client-area/approval/index" */))
const _52e27de4 = () => interopDefault(import('../pages/client-area/onboarding.vue' /* webpackChunkName: "pages/client-area/onboarding" */))
const _c0e61f54 = () => interopDefault(import('../pages/client-area/register.vue' /* webpackChunkName: "pages/client-area/register" */))
const _148ab927 = () => interopDefault(import('../pages/contracts/active.vue' /* webpackChunkName: "pages/contracts/active" */))
const _ba904468 = () => interopDefault(import('../pages/contracts/inactive.vue' /* webpackChunkName: "pages/contracts/inactive" */))
const _24c3f29e = () => interopDefault(import('../pages/contracts/sign.vue' /* webpackChunkName: "pages/contracts/sign" */))
const _34d7f4e7 = () => interopDefault(import('../pages/documents/registry.vue' /* webpackChunkName: "pages/documents/registry" */))
const _76fa6d80 = () => interopDefault(import('../pages/documents/validate.vue' /* webpackChunkName: "pages/documents/validate" */))
const _7ca872d0 = () => interopDefault(import('../pages/invoices/finalized.vue' /* webpackChunkName: "pages/invoices/finalized" */))
const _48124861 = () => interopDefault(import('../pages/invoices/pending.vue' /* webpackChunkName: "pages/invoices/pending" */))
const _4be18466 = () => interopDefault(import('../pages/jobs/offers.vue' /* webpackChunkName: "pages/jobs/offers" */))
const _5c3506d0 = () => interopDefault(import('../pages/jobs/reacted.vue' /* webpackChunkName: "pages/jobs/reacted" */))
const _22f4c650 = () => interopDefault(import('../pages/planning/admin.vue' /* webpackChunkName: "pages/planning/admin" */))
const _1b472146 = () => interopDefault(import('../pages/planning/approved.vue' /* webpackChunkName: "pages/planning/approved" */))
const _51a2db50 = () => interopDefault(import('../pages/planning/pending.vue' /* webpackChunkName: "pages/planning/pending" */))
const _20b2892b = () => interopDefault(import('../pages/planning/upcoming.vue' /* webpackChunkName: "pages/planning/upcoming" */))
const _04d90982 = () => interopDefault(import('../pages/planning/worked.vue' /* webpackChunkName: "pages/planning/worked" */))
const _7e612f21 = () => interopDefault(import('../pages/pre-boarding/pending.vue' /* webpackChunkName: "pages/pre-boarding/pending" */))
const _1f5532f4 = () => interopDefault(import('../pages/admin/contracts/manage.vue' /* webpackChunkName: "pages/admin/contracts/manage" */))
const _76dde99f = () => interopDefault(import('../pages/client-area/contract/manage.vue' /* webpackChunkName: "pages/client-area/contract/manage" */))
const _846c0954 = () => interopDefault(import('../pages/client-area/contract/new.vue' /* webpackChunkName: "pages/client-area/contract/new" */))
const _1bcf51e0 = () => interopDefault(import('../pages/client-area/invoices/finalized.vue' /* webpackChunkName: "pages/client-area/invoices/finalized" */))
const _74ae3ca1 = () => interopDefault(import('../pages/client-area/invoices/pending.vue' /* webpackChunkName: "pages/client-area/invoices/pending" */))
const _3422161b = () => interopDefault(import('../pages/client-area/location/details.vue' /* webpackChunkName: "pages/client-area/location/details" */))
const _6c4e89dc = () => interopDefault(import('../pages/client-area/work/long-term.vue' /* webpackChunkName: "pages/client-area/work/long-term" */))
const _2c8a6242 = () => interopDefault(import('../pages/client-area/work/requests.vue' /* webpackChunkName: "pages/client-area/work/requests" */))
const _4a2c53ba = () => interopDefault(import('../pages/client-area/work/single.vue' /* webpackChunkName: "pages/client-area/work/single" */))
const _4727c714 = () => interopDefault(import('../pages/planning/shift/new.vue' /* webpackChunkName: "pages/planning/shift/new" */))
const _58c2a001 = () => interopDefault(import('../pages/jobs/job/apply/_id.vue' /* webpackChunkName: "pages/jobs/job/apply/_id" */))
const _550a321e = () => interopDefault(import('../pages/planning/shift/edit/_id.vue' /* webpackChunkName: "pages/planning/shift/edit/_id" */))
const _3fd3acbc = () => interopDefault(import('../pages/jobs/job/_id.vue' /* webpackChunkName: "pages/jobs/job/_id" */))
const _3371f22e = () => interopDefault(import('../pages/planning/shift/_id.vue' /* webpackChunkName: "pages/planning/shift/_id" */))
const _42057169 = () => interopDefault(import('../pages/contracts/_id.vue' /* webpackChunkName: "pages/contracts/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/client-area",
    component: _06b647af,
    meta: {"name":"ClientAreaIndex","middleware":["auth-user","is-client-user","client-onboarded"]},
    name: "client-area___nl"
  }, {
    path: "/documents",
    component: _4416c318,
    meta: {"name":"IndexDocuments","middleware":"auth-user","title":"documents","back":"\u002F"},
    name: "documents___nl"
  }, {
    path: "/en",
    component: _316413ef,
    meta: {"name":"IndexPage","middleware":["auth-user","onboarded"]},
    name: "index___en"
  }, {
    path: "/forgot-password",
    component: _85e42d3c,
    meta: {"name":"ForgotPasswordPage","middleware":"guest"},
    name: "forgot-password___nl"
  }, {
    path: "/freelancers",
    component: _585fa470,
    meta: {"name":"ManageContracts","middleware":["auth-user"],"title":"freelancers","back":"\u002F"},
    name: "freelancers___nl"
  }, {
    path: "/gocredible",
    component: _7271b08a,
    meta: {"name":"GoCredible","middleware":"auth-user"},
    name: "gocredible___nl"
  }, {
    path: "/login",
    component: _09e891f4,
    meta: {"name":"LoginPage","middleware":"guest"},
    name: "login___nl"
  }, {
    path: "/onboarding",
    component: _4c52c664,
    meta: {"name":"OnboardingPage","middleware":["auth-user","preboarded"]},
    name: "onboarding___nl"
  }, {
    path: "/pre-boarding",
    component: _291aad5c,
    meta: {"name":"OnboardingPage","middleware":["auth-user","not-active-freelancer"]},
    name: "pre-boarding___nl"
  }, {
    path: "/profile",
    component: _6226e406,
    meta: {"name":"ProfilePage","middleware":["auth-user"],"validations":{"firstName":{"maxLength":undefined},"lastName":{"maxLength":undefined},"phone":{"minLength":undefined,"maxLength":undefined}},"title":"profile","back":"\u002F"},
    name: "profile___nl"
  }, {
    path: "/register",
    component: _3698bc16,
    meta: {"name":"RegisterPage","middleware":"guest"},
    name: "register___nl"
  }, {
    path: "/reset-password",
    component: _bfb408e8,
    meta: {"name":"ForgotPasswordPage","middleware":"guest"},
    name: "reset-password___nl"
  }, {
    path: "/client-area/approval",
    component: _9dc4e40e,
    meta: {"name":"ApprovalPage","middleware":["auth-user","is-client-user","client-onboarded"],"title":"shift_approval","back":"\u002Fclient-area"},
    name: "client-area-approval___nl"
  }, {
    path: "/client-area/onboarding",
    component: _52e27de4,
    meta: {"name":"ClientOnboardingPage","middleware":["auth-user","is-client-user","not-active"]},
    name: "client-area-onboarding___nl"
  }, {
    path: "/client-area/register",
    component: _c0e61f54,
    meta: {"name":"ClientRegisterPage","middleware":"guest"},
    name: "client-area-register___nl"
  }, {
    path: "/contracts/active",
    component: _148ab927,
    meta: {"name":"ActiveContractsPage","middleware":["auth-user","onboarded"],"title":"contracts","back":"\u002F"},
    name: "contracts-active___nl"
  }, {
    path: "/contracts/inactive",
    component: _ba904468,
    meta: {"name":"InactiveContractsPage","middleware":["auth-user","onboarded"],"title":"contracts","back":"\u002F"},
    name: "contracts-inactive___nl"
  }, {
    path: "/contracts/sign",
    component: _24c3f29e,
    meta: {"name":"SignPage","middleware":"auth-user","title":"contracts","back":"\u002F"},
    name: "contracts-sign___nl"
  }, {
    path: "/documents/registry",
    component: _34d7f4e7,
    meta: {"name":"DocumentRegistry","middleware":["auth-user","onboarded"],"title":"quality_registry","back":"\u002F"},
    name: "documents-registry___nl"
  }, {
    path: "/documents/validate",
    component: _76fa6d80,
    meta: {"name":"DocumentsPage","middleware":["auth-user","isAdmin"],"title":"documents","back":"\u002F"},
    name: "documents-validate___nl"
  }, {
    path: "/en/client-area",
    component: _06b647af,
    meta: {"name":"ClientAreaIndex","middleware":["auth-user","is-client-user","client-onboarded"]},
    name: "client-area___en"
  }, {
    path: "/en/documents",
    component: _4416c318,
    meta: {"name":"IndexDocuments","middleware":"auth-user","title":"documents","back":"\u002F"},
    name: "documents___en"
  }, {
    path: "/en/forgot-password",
    component: _85e42d3c,
    meta: {"name":"ForgotPasswordPage","middleware":"guest"},
    name: "forgot-password___en"
  }, {
    path: "/en/freelancers",
    component: _585fa470,
    meta: {"name":"ManageContracts","middleware":["auth-user"],"title":"freelancers","back":"\u002F"},
    name: "freelancers___en"
  }, {
    path: "/en/gocredible",
    component: _7271b08a,
    meta: {"name":"GoCredible","middleware":"auth-user"},
    name: "gocredible___en"
  }, {
    path: "/en/login",
    component: _09e891f4,
    meta: {"name":"LoginPage","middleware":"guest"},
    name: "login___en"
  }, {
    path: "/en/onboarding",
    component: _4c52c664,
    meta: {"name":"OnboardingPage","middleware":["auth-user","preboarded"]},
    name: "onboarding___en"
  }, {
    path: "/en/pre-boarding",
    component: _291aad5c,
    meta: {"name":"OnboardingPage","middleware":["auth-user","not-active-freelancer"]},
    name: "pre-boarding___en"
  }, {
    path: "/en/profile",
    component: _6226e406,
    meta: {"name":"ProfilePage","middleware":["auth-user"],"validations":{"firstName":{"maxLength":undefined},"lastName":{"maxLength":undefined},"phone":{"minLength":undefined,"maxLength":undefined}},"title":"profile","back":"\u002F"},
    name: "profile___en"
  }, {
    path: "/en/register",
    component: _3698bc16,
    meta: {"name":"RegisterPage","middleware":"guest"},
    name: "register___en"
  }, {
    path: "/en/reset-password",
    component: _bfb408e8,
    meta: {"name":"ForgotPasswordPage","middleware":"guest"},
    name: "reset-password___en"
  }, {
    path: "/invoices/finalized",
    component: _7ca872d0,
    meta: {"name":"InvoicesPage","middleware":["auth-user","onboarded"],"title":"finalized_invoices","back":"\u002F"},
    name: "invoices-finalized___nl"
  }, {
    path: "/invoices/pending",
    component: _48124861,
    meta: {"name":"InvoicesPage","middleware":["auth-user","onboarded"],"title":"pending_invoices","back":"\u002F"},
    name: "invoices-pending___nl"
  }, {
    path: "/jobs/offers",
    component: _4be18466,
    meta: {"name":"Offers","middleware":["auth-user","onboarded"],"title":"offers","back":"\u002F"},
    name: "jobs-offers___nl"
  }, {
    path: "/jobs/reacted",
    component: _5c3506d0,
    meta: {"name":"Reacted","middleware":["auth-user","onboarded"],"title":"reacted","back":"\u002F"},
    name: "jobs-reacted___nl"
  }, {
    path: "/planning/admin",
    component: _22f4c650,
    meta: {"name":"ApprovalPage","middleware":["auth-user","isAdmin"],"title":"shift_overview","back":"\u002F"},
    name: "planning-admin___nl"
  }, {
    path: "/planning/approved",
    component: _1b472146,
    meta: {"name":"ApprovedPlanning","middleware":["auth-user","onboarded"],"title":"approved","back":"\u002F"},
    name: "planning-approved___nl"
  }, {
    path: "/planning/pending",
    component: _51a2db50,
    meta: {"name":"PendingPlanning","middleware":["auth-user","onboarded"],"title":"pending","back":"\u002F"},
    name: "planning-pending___nl"
  }, {
    path: "/planning/upcoming",
    component: _20b2892b,
    meta: {"name":"UpcomingPlanning","middleware":["auth-user","onboarded"],"title":"planning","back":"\u002F"},
    name: "planning-upcoming___nl"
  }, {
    path: "/planning/worked",
    component: _04d90982,
    meta: {"name":"WorkedPlanning","middleware":["auth-user","onboarded"],"title":"worked","back":"\u002F"},
    name: "planning-worked___nl"
  }, {
    path: "/pre-boarding/pending",
    component: _7e612f21,
    meta: {"middleware":["auth-user","is-pending"]},
    name: "pre-boarding-pending___nl"
  }, {
    path: "/admin/contracts/manage",
    component: _1f5532f4,
    meta: {"name":"ManageContracts"},
    name: "admin-contracts-manage___nl"
  }, {
    path: "/client-area/contract/manage",
    component: _76dde99f,
    meta: {"name":"ManageContracts","middleware":["auth-user","is-client-user","client-onboarded"],"title":"contracts","back":"\u002F"},
    name: "client-area-contract-manage___nl"
  }, {
    path: "/client-area/contract/new",
    component: _846c0954,
    meta: {"name":"NewContractPage","middleware":["auth-user","is-client-user","client-onboarded"]},
    name: "client-area-contract-new___nl"
  }, {
    path: "/client-area/invoices/finalized",
    component: _1bcf51e0,
    meta: {"name":"InvoicesPage","title":"finalized_invoices","back":"\u002F"},
    name: "client-area-invoices-finalized___nl"
  }, {
    path: "/client-area/invoices/pending",
    component: _74ae3ca1,
    meta: {"name":"InvoicesPage","title":"pending_invoices","back":"\u002F"},
    name: "client-area-invoices-pending___nl"
  }, {
    path: "/client-area/location/details",
    component: _3422161b,
    meta: {"name":"LocationOnboardingPage","middleware":["auth-user","is-client-user"]},
    name: "client-area-location-details___nl"
  }, {
    path: "/client-area/work/long-term",
    component: _6c4e89dc,
    meta: {"name":"LongTermRequest","middleware":["auth-user","is-client-user","client-onboarded"],"back":"\u002Fclient-area\u002Fwork\u002Frequests"},
    name: "client-area-work-long-term___nl"
  }, {
    path: "/client-area/work/requests",
    component: _2c8a6242,
    meta: {"name":"RequestsPage","middleware":["auth-user","is-client-user","client-onboarded"],"title":"shift_overview","back":"\u002Fclient-area"},
    name: "client-area-work-requests___nl"
  }, {
    path: "/client-area/work/single",
    component: _4a2c53ba,
    meta: {"name":"SingleRequest","middleware":["auth-user","is-client-user","client-onboarded"],"back":"\u002Fclient-area\u002Fwork\u002Frequests"},
    name: "client-area-work-single___nl"
  }, {
    path: "/en/client-area/approval",
    component: _9dc4e40e,
    meta: {"name":"ApprovalPage","middleware":["auth-user","is-client-user","client-onboarded"],"title":"shift_approval","back":"\u002Fclient-area"},
    name: "client-area-approval___en"
  }, {
    path: "/en/client-area/onboarding",
    component: _52e27de4,
    meta: {"name":"ClientOnboardingPage","middleware":["auth-user","is-client-user","not-active"]},
    name: "client-area-onboarding___en"
  }, {
    path: "/en/client-area/register",
    component: _c0e61f54,
    meta: {"name":"ClientRegisterPage","middleware":"guest"},
    name: "client-area-register___en"
  }, {
    path: "/en/contracts/active",
    component: _148ab927,
    meta: {"name":"ActiveContractsPage","middleware":["auth-user","onboarded"],"title":"contracts","back":"\u002F"},
    name: "contracts-active___en"
  }, {
    path: "/en/contracts/inactive",
    component: _ba904468,
    meta: {"name":"InactiveContractsPage","middleware":["auth-user","onboarded"],"title":"contracts","back":"\u002F"},
    name: "contracts-inactive___en"
  }, {
    path: "/en/contracts/sign",
    component: _24c3f29e,
    meta: {"name":"SignPage","middleware":"auth-user","title":"contracts","back":"\u002F"},
    name: "contracts-sign___en"
  }, {
    path: "/en/documents/registry",
    component: _34d7f4e7,
    meta: {"name":"DocumentRegistry","middleware":["auth-user","onboarded"],"title":"quality_registry","back":"\u002F"},
    name: "documents-registry___en"
  }, {
    path: "/en/documents/validate",
    component: _76fa6d80,
    meta: {"name":"DocumentsPage","middleware":["auth-user","isAdmin"],"title":"documents","back":"\u002F"},
    name: "documents-validate___en"
  }, {
    path: "/en/invoices/finalized",
    component: _7ca872d0,
    meta: {"name":"InvoicesPage","middleware":["auth-user","onboarded"],"title":"finalized_invoices","back":"\u002F"},
    name: "invoices-finalized___en"
  }, {
    path: "/en/invoices/pending",
    component: _48124861,
    meta: {"name":"InvoicesPage","middleware":["auth-user","onboarded"],"title":"pending_invoices","back":"\u002F"},
    name: "invoices-pending___en"
  }, {
    path: "/en/jobs/offers",
    component: _4be18466,
    meta: {"name":"Offers","middleware":["auth-user","onboarded"],"title":"offers","back":"\u002F"},
    name: "jobs-offers___en"
  }, {
    path: "/en/jobs/reacted",
    component: _5c3506d0,
    meta: {"name":"Reacted","middleware":["auth-user","onboarded"],"title":"reacted","back":"\u002F"},
    name: "jobs-reacted___en"
  }, {
    path: "/en/planning/admin",
    component: _22f4c650,
    meta: {"name":"ApprovalPage","middleware":["auth-user","isAdmin"],"title":"shift_overview","back":"\u002F"},
    name: "planning-admin___en"
  }, {
    path: "/en/planning/approved",
    component: _1b472146,
    meta: {"name":"ApprovedPlanning","middleware":["auth-user","onboarded"],"title":"approved","back":"\u002F"},
    name: "planning-approved___en"
  }, {
    path: "/en/planning/pending",
    component: _51a2db50,
    meta: {"name":"PendingPlanning","middleware":["auth-user","onboarded"],"title":"pending","back":"\u002F"},
    name: "planning-pending___en"
  }, {
    path: "/en/planning/upcoming",
    component: _20b2892b,
    meta: {"name":"UpcomingPlanning","middleware":["auth-user","onboarded"],"title":"planning","back":"\u002F"},
    name: "planning-upcoming___en"
  }, {
    path: "/en/planning/worked",
    component: _04d90982,
    meta: {"name":"WorkedPlanning","middleware":["auth-user","onboarded"],"title":"worked","back":"\u002F"},
    name: "planning-worked___en"
  }, {
    path: "/en/pre-boarding/pending",
    component: _7e612f21,
    meta: {"middleware":["auth-user","is-pending"]},
    name: "pre-boarding-pending___en"
  }, {
    path: "/planning/shift/new",
    component: _4727c714,
    meta: {"name":"NewShift","middleware":["auth-user","onboarded"],"title":"schedule_shift","back":"\u002Fplanning\u002Fupcoming"},
    name: "planning-shift-new___nl"
  }, {
    path: "/en/admin/contracts/manage",
    component: _1f5532f4,
    meta: {"name":"ManageContracts"},
    name: "admin-contracts-manage___en"
  }, {
    path: "/en/client-area/contract/manage",
    component: _76dde99f,
    meta: {"name":"ManageContracts","middleware":["auth-user","is-client-user","client-onboarded"],"title":"contracts","back":"\u002F"},
    name: "client-area-contract-manage___en"
  }, {
    path: "/en/client-area/contract/new",
    component: _846c0954,
    meta: {"name":"NewContractPage","middleware":["auth-user","is-client-user","client-onboarded"]},
    name: "client-area-contract-new___en"
  }, {
    path: "/en/client-area/invoices/finalized",
    component: _1bcf51e0,
    meta: {"name":"InvoicesPage","title":"finalized_invoices","back":"\u002F"},
    name: "client-area-invoices-finalized___en"
  }, {
    path: "/en/client-area/invoices/pending",
    component: _74ae3ca1,
    meta: {"name":"InvoicesPage","title":"pending_invoices","back":"\u002F"},
    name: "client-area-invoices-pending___en"
  }, {
    path: "/en/client-area/location/details",
    component: _3422161b,
    meta: {"name":"LocationOnboardingPage","middleware":["auth-user","is-client-user"]},
    name: "client-area-location-details___en"
  }, {
    path: "/en/client-area/work/long-term",
    component: _6c4e89dc,
    meta: {"name":"LongTermRequest","middleware":["auth-user","is-client-user","client-onboarded"],"back":"\u002Fclient-area\u002Fwork\u002Frequests"},
    name: "client-area-work-long-term___en"
  }, {
    path: "/en/client-area/work/requests",
    component: _2c8a6242,
    meta: {"name":"RequestsPage","middleware":["auth-user","is-client-user","client-onboarded"],"title":"shift_overview","back":"\u002Fclient-area"},
    name: "client-area-work-requests___en"
  }, {
    path: "/en/client-area/work/single",
    component: _4a2c53ba,
    meta: {"name":"SingleRequest","middleware":["auth-user","is-client-user","client-onboarded"],"back":"\u002Fclient-area\u002Fwork\u002Frequests"},
    name: "client-area-work-single___en"
  }, {
    path: "/en/planning/shift/new",
    component: _4727c714,
    meta: {"name":"NewShift","middleware":["auth-user","onboarded"],"title":"schedule_shift","back":"\u002Fplanning\u002Fupcoming"},
    name: "planning-shift-new___en"
  }, {
    path: "/",
    component: _316413ef,
    meta: {"name":"IndexPage","middleware":["auth-user","onboarded"]},
    name: "index___nl"
  }, {
    path: "/en/jobs/job/apply/:id?",
    component: _58c2a001,
    meta: {"name":"ApplyJob","middleware":["auth-user","onboarded"],"title":"job_info","back":"\u002Fjobs\u002Foffers"},
    name: "jobs-job-apply-id___en"
  }, {
    path: "/en/planning/shift/edit/:id?",
    component: _550a321e,
    meta: {"name":"IndexEditShift","middleware":["auth-user","onboarded"],"title":"edit_shift"},
    name: "planning-shift-edit-id___en"
  }, {
    path: "/en/jobs/job/:id?",
    component: _3fd3acbc,
    meta: {"name":"SingleJob","title":"job_info","back":"\u002Fjobs\u002Foffers"},
    name: "jobs-job-id___en"
  }, {
    path: "/en/planning/shift/:id?",
    component: _3371f22e,
    meta: {"name":"IndexShift","middleware":["auth-user","onboarded"],"title":"shift_details"},
    name: "planning-shift-id___en"
  }, {
    path: "/jobs/job/apply/:id?",
    component: _58c2a001,
    meta: {"name":"ApplyJob","middleware":["auth-user","onboarded"],"title":"job_info","back":"\u002Fjobs\u002Foffers"},
    name: "jobs-job-apply-id___nl"
  }, {
    path: "/planning/shift/edit/:id?",
    component: _550a321e,
    meta: {"name":"IndexEditShift","middleware":["auth-user","onboarded"],"title":"edit_shift"},
    name: "planning-shift-edit-id___nl"
  }, {
    path: "/en/contracts/:id?",
    component: _42057169,
    meta: {"name":"IndexContract","middleware":["auth-user","onboarded"],"title":"contract_details"},
    name: "contracts-id___en"
  }, {
    path: "/jobs/job/:id?",
    component: _3fd3acbc,
    meta: {"name":"SingleJob","title":"job_info","back":"\u002Fjobs\u002Foffers"},
    name: "jobs-job-id___nl"
  }, {
    path: "/planning/shift/:id?",
    component: _3371f22e,
    meta: {"name":"IndexShift","middleware":["auth-user","onboarded"],"title":"shift_details"},
    name: "planning-shift-id___nl"
  }, {
    path: "/contracts/:id?",
    component: _42057169,
    meta: {"name":"IndexContract","middleware":["auth-user","onboarded"],"title":"contract_details"},
    name: "contracts-id___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
