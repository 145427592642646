const isAdmin = (store) => {
  const loggedInUser = store.getters.loggedInUser
  return loggedInUser && loggedInUser.role?.name === 'Admin'
}

const isClient = (store) => {
  const loggedInUser = store.getters.loggedInUser
  return loggedInUser && loggedInUser.role?.name === 'Client'
}

const isPreBoarded = (store) => {
  const loggedInUser = store.getters.loggedInUser
  return loggedInUser?.status === 'active'
}

const isPending = (store) => {
  const loggedInUser = store.getters.loggedInUser
  return loggedInUser?.status === 'pending'
}

export default function (context) {
  const { store, $auth, next, localePath } = context
  if (!store || !$auth || !next || !localePath) {
    return true
  }

  if (isAdmin(store)) {
    return true
  }

  if (isClient(store)) {
    return next({ path: localePath('/client-area') })
  }

  const pending = isPending(store)
  if (pending) {
    return next({ path: localePath('/pre-boarding/pending') })
  }

  const preBoarded = isPreBoarded(store)
  if (!preBoarded) {
    return next({ path: localePath('/pre-boarding') })
  }
}
