export const ApiConfig = {
  BASE_URL: process.env.API_URL,
  INVOICES: {
    GET_ALL: '/invoices',
    GET: '/invoices/me',
    GET_CLIENT: '/invoices/client',
    DOWNLOAD: '/invoices/file'
  },
  PAYMENTS: {
    GENERATE_PAYMENT: '/payments/%d/renew'
  },
  GOCREDIBLE: {
    GET_ONBOARDING: '/gocredible',
    GET_ACTIVE: '/gocredible/active',
    NEW_ONBOARDING: '/gocredible/new',
    DEACTIVATE: '/gocredible/deactivate'
  },
  EMREX: {
    SESSION: '/emrex/session'
  },
  KVK: {
    SEARCH: '/kvk/search/%d',
    DETAIL: '/kvk/details/%d'
  },
  BIG: {
    VALIDATE: '/big/validate/%d'
  },
  ONBOARDING: {
    UPDATE: '/onboarding/update',
    UPDATE_INTRODUCTION: '/onboarding/update/introduction',
    UPDATE_MATCHMAKING: '/onboarding/update/matchmaking'
  },
  USERS: {
    GET_ALL: '/users'
  },
  USER: {
    UPDATE: '/user/update',
    UPDATE_REGISTRATION: '/user/update/registration'
  },
  DOCUMENTS: {
    GET_ALL: '/documents/all',
    GET_ALLOWED_CLIENT: '/documents/client',
    GET: '/documents',
    GET_FILE: '/documents/file/%d',
    VERIFICATION: '/documents/%d/verify',
    CREATE: '/documents',
    DELETE: '/documents/%d',
    UPDATE: '/documents/updateCompetenceDate'
  },
  SHIFTS: {
    GET_UPCOMING: '/shifts/upcoming',
    GET_PENDING: '/shifts/pending',
    GET_APPROVED: '/shifts/approved',
    GET_WORKED: '/shifts/worked',
    FIND_ONE: '/shifts/findOne',
    FIND_ALL: '/shifts/findAll',
    CREATE: '/shifts/create',
    UPDATE: '/shifts/update',
    APPROVE: '/shifts/approve',
    APPROVE_CHANGES: '/shifts/update/approve',
    DELETE: '/shifts/delete',
    FIND_BY_LOCATION: '/shifts/location',
    APPROVE_BY_LOCATION: '/shifts/location/approve',
    REJECT_BY_LOCATION: '/shifts/location/reject'
  },
  SHIFT_REQUESTS: {
    CREATE: '/shift-requests',
    FIND: '/shift-requests',
    UPDATE: '/shift-requests/%d',
    UPDATE_CANDIDATE: '/shift-requests/%d/candidate',
    APPLY: '/shift-requests/apply',
    ARCHIVE: '/shift-requests/%d/archive',
    FIND_BY_CLIENT: '/shift-requests/client',
    TOGGLE: '/shift-requests/toggle',
    GET_FILE: '/shift-requests/file/%d'
  },
  CLIENTS: {
    GET_ALL: '/clients'
  },
  HIRE_COMPANY: {
    GET: '/hire-companies/me',
    UPDATE_ADDRESS: '/hire-companies/update-address'
  },
  AUTH: {
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password'
  },
  PRE_BOARDING: {
    SAVE_INTRODUCTION: '/pre-boarding/introduction',
    SAVE_ADDITIONAL: '/pre-boarding/additional',
    SAVE_PREFERENCES: '/pre-boarding/preferences',
    FINISH: '/pre-boarding/finish'
  },
  FUNCTION: {
    FIND_ALL: '/functions'
  },
  SKILL: {
    FIND_ALL: '/skills',
    CREATE: '/skills?id=%d'
  },
  USER_PREFERENCES: {
    UPDATE: '/user-perferences/notifications',
    UPDATE_FREELANCER_DATA: '/user-perferences/update'
  },
  WORK_TYPE: {
    FIND_ALL: '/work-types',
    CREATE: '/work-types?id=%d'
  },
  BLOCK_RATE: {
    CREATE: '/block-rates?id=%d'
  },
  LOCATION: {
    CREATE: '/locations',
    UPDATE: '/locations/%d',
    ARCHIVE: '/locations/%d/archive'
  },
  CONTRACT: {
    CREATE: '/contracts',
    FIND_ALL: '/contracts',
    FIND_RESTRICTED: '/contract/restricted',
    SIGN: '/contract/sign',
    GET_SIGNING_URL: '/contract/signing-url',
    CHECK_CONTRACT: '/contract/check-contract',
    CONNECT_SHIFT_REQUEST: '/contract/add-shiftRequest',
    DELETE: '/contract/delete',
    VOIDED: '/contract/voided',
    SHOW: '/contract/show',
    FIND_BY_ID: '/contract/find-by-id/%d'
  },
  OPEN_AI: {
    GENERATE: '/openai/generate'
  },
  FREELANCER: {
    FIND_ALL: '/freelancers',
    FIND: '/freelancers',
    FIND_ONE: '/freelancers/%d',
    UPDATE: '/freelancers/update-freelancer',
    FIND_BY_FREELANCER_ID: '/freelancers/find-by-freelancer-id/%d'
  },
  FAVORITE_FREELANCERS: {
    FIND_ALL: '/favorite-freelancers',
    TOGGLE: '/favorite-freelancers/toggle'
  },
  REFERENCES: {
    CREATE: '/references'
  }
}
